<template>
  <div class="app-container">
    <breadcrumb-nav>
      <template v-slot:firstMenu>数据统计</template>
      <template v-slot:secondMenu>用户统计</template>
    </breadcrumb-nav>
    <el-card>
      <div class="overview-layout">
        <el-row :gutter="24">
         <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">今日活跃</div>
            <div class="layout-content" style="padding: 20px">
              <el-row>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserActive.incVip}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserActive.incVisit}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserActive.activeVip}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserActive.activeVisit}}</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="6" class="overview-item-title">新增会员</el-col>
                <el-col :span="6" class="overview-item-title">新增游客</el-col>
                <el-col :span="6" class="overview-item-title">活跃会员</el-col>
                <el-col :span="6" class="overview-item-title">活跃游客</el-col>
              </el-row>
            </div>
          </div>
         </el-col>
         <el-col :span="12">
          <div class="out-border">
            <div class="layout-title">用户总览</div>
            <div class="layout-content" style="padding: 20px">
              <el-row>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserTotal.totalVip}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserTotal.totalVisit}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserTotal.totalUnbind}}</el-col>
                <el-col :span="6" class="color-danger overview-item-value">{{statUserTotal.totalUnregist}}</el-col>
              </el-row>
              <el-row class="font-medium">
                <el-col :span="6" class="overview-item-title">总会员数</el-col>
                <el-col :span="6" class="overview-item-title">总游客数</el-col>
                <el-col :span="6" class="overview-item-title">解绑数</el-col>
                <el-col :span="6" class="overview-item-title">注销数</el-col>
              </el-row>
            </div>
          </div>
         </el-col>
       </el-row>
      </div>
      <div class="overview-layout" style="margin-top: 20px">
        <el-row :gutter="24">
          <el-col :span="12">
            <div class="out-border">
              <div class="layout-title">新增用户</div>
              <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline" style="margin-top: 10px;margin-left: 5px;">
              <el-form-item label="日期范围" prop="date">
                <el-date-picker
                  v-model="listQuery.date"
                  type="daterange"
                  align="right"
                  prop="date"
                  value-format="timestamp"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button @click="resetForm('listQuery')">重置</el-button>
              </el-form-item>
            </el-form>
              <div id="inc-chart" style="width: 95%;height: 350px;"></div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="out-border">
              <div class="layout-title">活跃用户</div>
              <el-form :model="activeListQuery" ref="activeListQuery" :inline="true" class="demo-form-inline" style="margin-top: 10px;margin-left: 5px;">
                <el-form-item label="日期范围" prop="date">
                  <el-date-picker
                    v-model="activeListQuery.date"
                    type="daterange"
                    align="right"
                    prop="date"
                    value-format="timestamp"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="activePickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmitActive">查询</el-button>
                  <el-button @click="resetForm('activeListQuery')">重置</el-button>
                </el-form-item>
              </el-form>
              <div id="active-chart" style="width: 95%;height: 350px;"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getStatUserTotal, getStatUserToday, listStatIncUserHistory, listStatActiveUserHistory } from '../../network/statistics'
import * as echarts from 'echarts'
export default {
  name: 'StatUser',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      statUserActive: {
        incVisit: 0,
        incVip: 0,
        activeVisit: 0,
        activeVip: 0
      },
      statUserTotal: {
        totalVisit: 0,
        totalVip: 0,
        totalUnbind: 0,
        totalUnregist: 0
      },
      // 查询参数
      listQuery: {
        date: '',
        statType: 2
      },
      activeListQuery: {
        date: '',
        statType: 1
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      activePickerOptions: {
        shortcuts: [
          {
            text: '最近三天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      incChart: '',
      activeChart: '',
      options: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            }
          }
        ],
        legend: {
          show: false
        },
        series: [{
          name: '新增',
          type: 'line',
          smooth: true
        }]
      },
      activeOptions: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#E9EEF3'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            }
          }
        ],
        legend: {
          show: false
        },
        series: [{
          name: '活跃',
          type: 'line',
          smooth: true
        }]
      },
      incUserList: [],
      activeUserList: []
    }
  },
  mounted () {
    this.incChart = echarts.init(document.getElementById('inc-chart'))
    this.activeChart = echarts.init(document.getElementById('active-chart'))
    this.getStatTotal()
    this.getStatToday()
    this.getStatIncData()
    this.getStatActiveData()
  },
  methods: {
    getStatTotal () {
      getStatUserTotal({}).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        this.statUserTotal = result.data
      })
    },
    getStatToday () {
      getStatUserToday({}).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        const data = result.data
        this.statUserActive.incVisit = data.incVisit.userCount
        this.statUserActive.incVip = data.incVip.userCount
        this.statUserActive.activeVisit = data.activeVisit.userCount
        this.statUserActive.activeVip = data.activeVip.userCount
      })
    },
    onSubmit () {
      this.getStatIncData()
    },
    onSubmitActive () {
      this.getStatActiveData()
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    getStatIncData () {
      const query = {
        statType: this.listQuery.statType
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      listStatIncUserHistory(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.incUserList = result.data.list
        this.setIncChartData()
      })
    },
    formatDate (value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    setIncChartData () {
      this.options.xAxis[0].data = []
      this.options.series[0].data = []
      for (let i = 0; i < this.incUserList.length; i++) {
        this.options.xAxis[0].data.push(this.formatDate(this.incUserList[i].dayTime))
        this.options.series[0].data.push(this.incUserList[i].userCount)
      }
      this.incChart.setOption(this.options)
    },
    getStatActiveData () {
      const query = {
        statType: this.activeListQuery.statType
      }
      if (this.activeListQuery.date !== null && this.activeListQuery.date !== undefined && this.activeListQuery.date.length > 1) {
        query.from = this.activeListQuery.date[0]
        query.to = this.activeListQuery.date[1]
      }
      listStatActiveUserHistory(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.activeUserList = result.data.list
        this.setActiveChartData()
      })
    },
    setActiveChartData () {
      this.activeOptions.xAxis[0].data = []
      this.activeOptions.series[0].data = []
      for (let i = 0; i < this.activeUserList.length; i++) {
        this.activeOptions.xAxis[0].data.push(this.formatDate(this.activeUserList[i].dayTime))
        this.activeOptions.series[0].data.push(this.activeUserList[i].userCount)
      }
      this.activeChart.setOption(this.activeOptions)
    }
  }
}
</script>

<style scoped>
.app-container {
}

.address-layout {
}

.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #DCDFE6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409EFF;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -50px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -40px;
}

.un-handle-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #F2F6FC;
  font-weight: bold;
}

.layout-content {
  background: #ffffff;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #EBEEF5;
  padding: 10px;
}

.overview-layout {
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #DCDFE6;
}

.color-danger{
  color: #F56C6C;
}

.statistics-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}
.mine-layout {
  position: absolute;
  right: 140px;
  top: 107px;
  width: 250px;
  height: 235px;
}
.address-content{
  padding: 20px;
  font-size: 18px
}
</style>
